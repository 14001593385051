<template>
  <div class="home">
    <div class="bg">
      <div class="content">
        <div class="list">
          <div class="box">
            <div class="li" v-for="item in list" :key="item">
              <img src="../assets/user.png" class="img" alt="" />
              <span class="mr-2">{{ item }}**</span>
              <span
                >已领取看短剧<span style="color: red">赢奖励福利</span></span
              >
            </div>
          </div>
        </div>
        <button type="button" class="button" @click="setOrder">
          <span style="margin-right: 5vw">领</span>
          <span>取</span>
        </button>
        <div class="text" @click="select = !select">
          <img :src="change" alt="" class="changebox" v-if="select" />
          <img :src="change_old" class="changebox" v-else alt="" />
          <span>提交即您已阅读并同意</span>
          <span
            style="color: #ff8400"
            @click="$router.push('/privacy-agreement')"
            >《用户隐私协议》</span
          >
        </div>
      </div>
      <div class="footer">
        <div>广西智谋传媒有限公司</div>
        <div>桂ICP备2022005793号</div>
        <div>客服电话：400-6771-707</div>
      </div>
    </div>
    <div class="fixedWrap">
      <button type="button" class="fixedItem" @click="popUp = true">
        活动规则
      </button>
      <button
        type="button"
        class="fixedItem"
        @click="openUrl($config.CustomerService)"
      >
        在线客服
      </button>
      <button type="button" class="fixedItem" @click="openUrl($config.Refund)">
        申请退款
      </button>
    </div>
    <div class="payPopWrap" v-if="popUp">
      <div class="payPopMain">
        <div class="popTitle">活动规则说明</div>
        <div class="rulesOne">
          <div>福气好运带回家，好梦到天明！</div>
          <br />
          <div>
            解锁短剧剧情费用说明:
            本链接下99.8元为看剧平台漫微剧场的30集剧情解锁费用，非商品直购费用，成功解锁短剧剧情即可参与抽现金红包活动
            (每人每日只能参与一次)。
          </div>
          <br />
          <div>【特殊说明】</div>
          <div>
            本活动为用户自愿参与，活动主办方为“广西智谋传媒有限公司”，如有疑问请联系主办方广西智谋传媒有限公司专属客服，客服电话：400-6771-707。在活动中获得道具、奖品后，如退出活动，可能导致道具或奖品丢失，请在获得道具、奖品后及时兑换或领取，避免损失。
          </div>
          <br />
          <div>本活动说明</div>
          <div>
            1.
            页面中展示的参与次数，为同一用户每天的参与次数；用户依据页面提示参与活动后，有机会获得相应礼品。参与活动用户的设备不同，参与的步骤可能存在不同。参与活动的具体操作步骤请以进入活动页面时的实时展示为准。参与活动后，中奖的用户应在活动页面、奖品详情页面所提示的时间内领奖，逾期未领，即视为放弃。
          </div>
          <div>
            2.
            实物类奖品将在中奖后30个工作日内发货，请注意查收；金币等虚拟奖品将在中奖后72小时内充值进用户填写的账户。敬请认真、准确填写领取账号，因个人原因填写错误，导致充值失败的，将不给予补发。
          </div>
          <div>
            3.
            因不可抗力、技术故障等原因，我们有权相应调整或终止活动，而无需向用户进行赔偿或补偿。
          </div>
          <div>
            4.
            活动期间内，用户存在作弊等违法违规行为的，将被撤销抽奖资格、奖品使用资格，收回已获得权益。
          </div>
          <div>
            5.
            对活动规则、奖品使用规则、奖品领取等有疑问的，请在工作日（上午9:00--下午18:00），按如下方式联系客服
          </div>
          <div>客服电话：400-6771-707</div>
          <br />
          <div>【金币使用说明】</div>
          <div>
            用户获得的金币可选择直接跳转到看剧页面，自动扣除对应数量的金币即可看剧。不同剧集对应扣除不同数额的金币，看剧所用金币金额以实际标识为准，如需充值继续观看，请联系在线客服咨询。
          </div>
          <br />
          <div>【中奖概率说明】</div>
          <div>惊喜一：888元现金红包一份，中奖概率0.00001%</div>
          <div>惊喜二：188元现金红包一份，中奖概率0.0001%</div>
          <div>惊喜三：88元现金红包一份，中奖概率0.001%</div>
          <div>惊喜四：平台30集黑金卡一张，中奖概率66.6%</div>
          <div>以上奖品，每人每天限领取1次</div>
          <div>
            活动真实有效，特此证明！活动相关问题可以联系广西智谋传媒有限公司
          </div>
        </div>
        <div class="btn" @click="popUp = false">我知道了</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import $config from "../../config";
import axios from "axios";
@Options({
  components: {},
})
export default class HomeView extends Vue {
  // steup
  list = ["张", "王", "李", "赵", "张"];
  popUp = false;
  change = require("../assets/change.png");
  change_old = require("../assets/change-old.png");
  select = true;
  Receive = "";
  debounce = true;

  // mounted(): void {
  //   this.Receive = this.$config.Receive
  // }

  setOrder = () => {
    if (!this.debounce) {
      alert("请勿重复领取");
      return;
    }
    if (!this.select) {
      alert("请阅读用户隐私协议并勾选后操作");
      return;
    }
    let search: string = "?" + (location.href.split("?")[1] || "");
    // let Receive = $config.Receive
    axios
      .post($config.Receive + search, { activityId: 108 })
      .then((res) => {
        console.log("setOrder --->", res);
        if (res.data.code == 0 && res.data.data) {
          this.debounce = false;
          window.location.href = res.data.data.payData;
        } else {
          alert("服务器繁忙请稍后重试");
        }
      })
      .catch((err) => {
        alert("服务器繁忙请稍后重试");
      });
  };
}
</script>
<style lang="less" scoped>
.home {
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background: #c51908;
  ::-webkit-scrollbar {
    display: none;
  }
  .bg {
    background: url("../assets/888-79.9-1.jpg") center top no-repeat #c51908;
    background-size: 100% auto;
    // margin: 0 auto;
    // width: calc(100vw - 60px);
    width: 100vw;
    height: 210.9333333vw;
    display: flex;
    flex-direction: column;
  }

  .content {
    // border: 1px solid #c51908;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 119.5vw;
    width: 100vw;
    height: 70vw;
    align-items: center;
    .list {
      height: 10vw;
      overflow: hidden;
      padding: 0;
      margin: 0;
      display: block;
      .box {
        animation: list-first 5s infinite;
        display: flex;
        flex-direction: column;
        .li {
          // border: 1px solid red;
          border: 0;
          height: 10vw;
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            font-size: 3vw;
          }
          .img {
            margin: 0;
            padding: 0;
            height: 5vw;
            width: 5vw;
            margin-right: 2vw;
          }
        }
      }
    }
    .button {
      animation: button-first 1.5s infinite;
      width: 50%;
      height: 10vw;
      border-radius: 50vw;
      font-size: 5vw;
      display: flex;
      justify-content: center;
      align-items: center;
      // border: 0.5px solid #;
      background-image: linear-gradient(180deg, #fe7a4d 0, #f71c1e);
      color: #fff;
      border: 0;
    }
    .text {
      display: flex;
      justify-content: center;
      align-items: center;
      .changebox {
        width: 5vw;
        height: 5vw;
        margin-right: 1vw;
      }
    }
  }
  .footer {
    margin-top: 5vw;
    color: #fff;
  }
  .fixedWrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    position: fixed;
    right: -1px;
    top: 38px;
    z-index: 999;
    font-size: 12px;
    .fixedItem {
      background: linear-gradient(180deg, #f7efdf, #ecc561);
      color: #fe5903;
      border: 2px solid #fff;
      border-right: 0;
      box-shadow: 0 0 80px rgba(94, 94, 94, 0.25882352941176473);
      border-radius: 17px 0 0 17px;
      -webkit-border-radius: 17px 0 0 17px;
      -moz-border-radius: 17px 0 0 17px;
      -ms-border-radius: 17px 0 0 17px;
      -o-border-radius: 17px 0 0 17px;
      line-height: 15px;
      padding: 3px 5px;
      text-align: center;
      word-wrap: break-word;
      margin-top: 8px;
    }
  }
  .payPopWrap {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(12, 13, 15, 0.6196078431372549);
    .payPopMain {
      display: flex;
      box-sizing: border-box;
      flex-direction: column;
      align-items: start;
      text-align: left;
      line-height: 18px;
      padding: 0 33px 20px;
      width: 350px;
      font-family: PingFang SC-Semibold, PingFang SC;
      background: #fff;
      border-radius: 12px;
    }
    .rulesOne {
      height: 50vh;
      font-size: 12px;
      color: #6d6d6d;
      overflow-y: scroll;
    }
    .popTitle {
      width: 100%;
      height: 60px;
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      color: #444;
      line-height: 60px;
    }
    .btn {
      margin: 18px auto;
      width: 224px;
      height: 36px;
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      color: #fff;
      line-height: 36px;
      background: #ff1b47;
      border-radius: 40px;
    }
  }
}
@keyframes button-first {
  0% {
    width: 50%;
    height: 10vw;
  }
  50% {
    width: 80%;
    height: 15vw;
  }
  100% {
    width: 50%;
    height: 10vw;
  }
}
@keyframes list-first {
  0% {
    margin-top: 0vw;
  }
  25% {
    margin-top: -10vw;
  }
  50% {
    margin-top: -20vw;
  }
  75% {
    margin-top: -30vw;
  }
  100% {
    margin-top: -40vw;
  }
}
</style>