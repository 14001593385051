import { createRouter, createWebHistory, RouteRecordRaw, createWebHashHistory } from 'vue-router'
import HomeView from '../views/index.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/success',
    name: 'success',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/pay-success.vue')
  },
  {
    path: '/privacy-agreement',
    name: 'privacyAgreement',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/privacy-agreement.vue')
  },
  {
    path: '/placeAnOrder',
    name: 'placeAnOrder',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PlaceAnOrder.vue')
  },
  {
    path: '/paymentPage',
    name: 'PaymentPage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PaymentPage.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(), //createWebHistory(process.env.BASE_URL)
  routes
})

export default router
